<template>
  <div class="app-container">
    <el-card class="box-card" style="margin:10px 0">
      <Player :currSrc="currSrc" ref="player"></Player>
    </el-card>

      <el-row :gutter="20" type="flex" align="middle" justify="start" style="margin-bottom:10px">
        <el-col :span="4">
          <el-input placeholder="请输入内容" v-model="search_op.search_name" @change="fetchData(1)" clearable>
            <template slot="prepend">音效名</template>
          </el-input>
        </el-col>
        <el-col :span="1.5">
          创建时间
        </el-col>
        <el-col :span="6">
          <el-date-picker
            @change="fetchData(1)"
            v-model="search_op.create_time_range"
            type="daterange"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-select v-model="search_op.status" placeholder="请选择音效状态" @change="fetchData(1)">
            <el-option
              v-for="item in status_arr"
              :key="item.value"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <!-- <el-row :gutter="20" type="flex" align="middle" justify="start" class="mt15">
      </el-row> -->

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      @selection-change="handleSelectionChange"
      ref="tableList"
    >
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column align="center" label="ID" width="95">
        <template slot-scope="scope">
          {{ scope.$index+1 }}
        </template>
      </el-table-column>
      <el-table-column label="音效名">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="已经加入的菜单">
        <template slot-scope="scope">
          <template v-for="(m,index) in (scope.row.menus_name?scope.row.menus_name.split(','):[])">
            <span :key="index">
              {{m?m.split('|')[0]:''}}<el-button v-if="m" type="text" @click="gotoMenu(m)">修改</el-button>
            </span>
          </template>
        </template>
      </el-table-column>

      <el-table-column label="状态" width="70">
        <template slot-scope="scope">
          <template v-if="scope.row.status==1">开启</template>
          <template v-else>关闭</template>
        </template>
      </el-table-column>

      <el-table-column label="试听" width="110" align="center">
        <template slot-scope="scope">
          <i class="el-icon-video-play" style="font-size:24px" @click="play(scope.row)"></i>
        </template>
      </el-table-column>

      <el-table-column label="创建时间" width="150" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.create_time_str}}</span>
        </template>
      </el-table-column>

      <el-table-column label="编辑" align="right">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" @click="editSound(scope.row)">编辑音效</el-button>
          <el-button type="danger" icon="el-icon-delete" @click="deleteSound(scope.row)">删除</el-button>
        </template>
      </el-table-column>

    </el-table>


    <el-card class="box-card" shadow="never" style="margin:5px 0">
      <el-row :gutter="5" type="flex" justify="center" align="middle">
        <el-col :span="3">已选择 {{soundsSelection.length}} 条</el-col>
        <el-col :span="3">设置到音效分类</el-col>
        <el-col :span="5"><el-button size="small" @click="setMenu()" v-show="soundsSelection.length>0">设置</el-button></el-col>
      </el-row>
    </el-card>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page_num"
      :page-sizes="[5,10,15,20]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>

    <el-dialog
      :append-to-body="true"
      title="设置到音效分类"
      :visible.sync="dialogVisible1"
      width="30%">
      <el-row :gutter="20" style="margin-bottom:10px">
        <el-col :span="12">
          <span style="font-size:16px">
            已选择 {{soundsSelection.length}} 个音效
          </span>
        </el-col>
      </el-row>
      <el-row>
        <el-select v-model="setMenuForm.menu_id" placeholder="请选择">
            <el-option
            v-for="item in menus"
            :key="item.id"
            :label="item.name +' 已经包含音效 '+item.sounds_num +'个'"
            :value="item.id">
            </el-option>
        </el-select>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="setMenuDone">确认设置</el-button>
      </span>
    </el-dialog>
    
    <el-dialog
      :append-to-body="true"
      title="编辑音效"
      :visible.sync="dialogVisible2"
      width="30%">

        <el-form ref="form" :model="editSoundForm" label-width="80px">
            <el-form-item label="音效名">
                <el-input v-model="editSoundForm.name"></el-input>
            </el-form-item>
            <el-form-item label="开启状态">
                <el-switch v-model="editSoundForm.status" :active-value="1" :inactive-value="0"></el-switch>
            </el-form-item>
        </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="editSoundDone">确认设置</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { updateSoundMenu, read as readSounds, updateSound, removeSound} from '@/api/soundsv2'
import { read as readMenus} from '@/api/categoryv2'
import Player from "@/components/Player.vue"
export default {
  components:{
    Player
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  },
  data() {
    return {
      list: null,
      listLoading: true,
      page_num:1,
      page_size:5,
      status_arr:[
          {name:'启用/激活',value:1},
          {name:'禁用/未激活',value:0},
          {name:'所有',value:null},
      ],
      total:0,
      search_op:{},
      audio: {
          name: '',
          artist: '蜗牛音效',
          url: '',
          cover: '', // prettier-ignore
          // lrc: 'https://cdn.moefe.org/music/lrc/thing.lrc',
      },
      soundsSelection:[],
      dialogVisible1:false,
      dialogVisible2:false, //编辑音效
      menus:[],
      setMenuForm:{},
      editSoundForm:{}
    }
  },
  created() {
    this.fetchData()
    this.fetchMenus()
  },
  methods: {
    gotoMenu(m){
      this.$router.push({ path: '/sounds_v2/edit_menu', query: {menu_id:m.split('|')[1] }})
    },
    //删除音效
    deleteSound(row){
      this.$confirm('确认删除该音效, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        removeSound({sound_id:row.id}).then(response => {
          if(response.data.code = 1){
            this.$message({
              type:"success",
              message:'删除成功'
            })
            this.fetchData(true)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });
    },
    editSound(row){
      console.log("设置")
      this.dialogVisible2 = true
      this.editSoundForm = row
    },
    editSoundDone(){
      
      let op = {
        'sound_id':this.editSoundForm.id,
        ...this.editSoundForm
      }
      updateSound(op).then(res=>{
        if(res.data.code == 1){
          this.$message({
            type:"success",
            message:'修改成功'
          })
          this.dialogVisible2 = false
          this.fetchData()
        }
      }).catch(err=>{
        this.$message({
          type:"error",
          message:'设置失败'
        })
      })
    },
    setMenu(){
      console.log("设置")
      this.dialogVisible1 = true
    },
    setMenuDone(){
      this.dialogVisible1 = false
      console.log("设置")
      let sounds_id_arr = this.soundsSelection.map(v=>{return v.id})
      // console.log('sounds_id_arr',sounds_id_arr)
      // return;
      let op = {
        'sounds_id_arr':sounds_id_arr,
        'category_v2_id':this.setMenuForm.menu_id
      }
      updateSoundMenu(op).then(res=>{
        if(res.data.code == 1){
          this.$message({
            type:"success",
            message:'设置成功'
          })
          this.$refs.tableList.clearSelection();
          this.fetchMenus();
          this.fetchData()
        }
      }).catch(err=>{
        this.$message({
          type:"error",
          message:'设置失败'
        })
      })
    },
    play(row){

      let item = row
      let full_path = `/apis${item.path}`;
      if(typeof item.hostname != "undefined" && item.hostname != '' && item.hostname !=null){
        full_path = `${item.hostname}/${item.path}`;
      }
      if(typeof item.full_path != "undefined" && item.full_path != '' && item.full_path !=null){
        full_path = item.full_path;
      }
      // this.currSrc = full_path;
      this.$refs.player.play({
        src:full_path,
        name:item.name
      })
    },
    handleSizeChange(val) {
      this.page_size = val
      this.fetchData(false)
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.page_num = val
      this.fetchData(false)
      console.log(`当前页: ${val}`);
    },
    fetchData(isSearch=false) {
      if(isSearch){
        this.page_num = 1;
      }
      const op = {
        page_num:this.page_num,
        page_size:this.page_size
      }
      Object.assign(op,this.search_op)
      this.listLoading = true
      readSounds(op).then(response => {
        this.list = response.data.data.list
        this.total = response.data.data.count
        this.listLoading = false
      })
    },
    fetchMenus(){
      const op = {
        page_size:100
      }
      readMenus().then(res=>{
        this.menus = res.data.data.list
      })
    },
    handleSelectionChange(val) {
      this.soundsSelection = val;
      console.log(this.soundsSelection)
    },

  }
}
</script>

<style lang="scss" scoped>
.app-container{
  width: 95%;
  margin: auto;
}
</style>