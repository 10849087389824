<template>
<div>
    <p>{{name}}</p>
    <vue-plyr>
        <audio controls playsinline autoplay :src="src">
        </audio>
    </vue-plyr>  

</div>
</template>

<script>
export default {
    name:"Player",
    data(){
        return {
            name:"",
            src:""
        }
    },
    props:{
        currSrc:{
            type:String,
            default:""
        }
    },
    methods:{
        play(opt){
            this.name = opt.name;
            this.src = opt.src
        }
    }
}
</script>

<style>

</style>